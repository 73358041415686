import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueMeta from 'vue-meta';
import Vuelidate from 'vuelidate';
import VuePageTransition from 'vue-page-transition';
import axios from 'axios'
import CKEditor from 'ckeditor4-vue'
import VueApexCharts from 'vue-apexcharts'
import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'
import Multiselect from 'vue-multiselect';
import EmptyState from './views/static/EmptyState.vue'

Vue.config.productionTip = false
Vue.prototype.$http       = axios

// Vue Use
Vue.use(VueMeta);
Vue.use(Vuelidate);
Vue.use(VueSweetalert2);
Vue.use(VuePageTransition);
Vue.use(CKEditor)
Vue.component('empty-state', EmptyState);
Vue.component('multiselect', Multiselect);
Vue.component('apexchart', VueApexCharts)

// Add Global Auth Token
const token = localStorage.getItem('auth_token')
if (token) {
  axios.defaults.headers.common['Authorization'] = token
}

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')

console.log(location.hostname);