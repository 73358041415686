<template>
    <div>
        <div class="page-content">
            <div class="container-fluid">

                <div v-if="dataFetch" class="row card">
                    <div class="card-body">
                        <div class="d-flex align-items-center justify-content-between">
                            <div class="mr-3 w-100">
                                <div class="d-flex align-items-center">
                                    <div class="w-100">
                                        <div class="skeleton-bar">
                                            <div class="sb-sm m-b-2"></div>
                                            <hr/>
                                            <div class="sb-sm sb-thin mb-2"></div>
                                            <div class="sb-md sb-thin mb-2"></div>
                                            <div class="sb-md sb-thin mb-4"></div>

                                            <div class="sb-md sb-thin mb-2"></div>
                                            <div class="sb-lg sb-thin mb-2"></div>
                                            <div class="sb-lg sb-thin mb-4"></div>

                                            <div class="sb-md sb-thin mb-2"></div>
                                            <div class="sb-lg sb-thin mb-2"></div>
                                            <div class="sb-lg sb-thin mb-4"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="!dataFetch" class="row">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-body">
                                <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 class="mb-sm-0">{{this.label}}</h4>
                                    <div class="page-title-right">
                                        <ol class="breadcrumb m-0">
                                            <li class="breadcrumb-item active">Account settings</li>
                                        </ol>
                                    </div>
                                </div>

                                <form v-on:submit.prevent="onSubmit">
                                    <div class="row">
                                        <input type="hidden" name="id" v-model="form.id">
                                        <div class="form-group col-md-6 mt-3">
                                            <label >Username</label>
                                            <input type="text" class="form-control"
                                                placeholder="Enter username..." v-model="form.username" :class="{ 'is-invalid': submitted && $v.form.username.$error }">
                                            <div v-if="submitted && !$v.form.username.required" class="invalid-feedback">Username is required</div>
                                            <small><i>Username cannot be the same as another user.</i></small>
                                        </div>
                                        <div class="form-group col-md-6 mt-3">
                                            <label >Fullname</label>
                                            <input type="text" class="form-control"
                                                placeholder="Enter data..." v-model="form.fullname" :class="{ 'is-invalid': submitted && $v.form.fullname.$error }">
                                            <div v-if="submitted && !$v.form.fullname.required" class="invalid-feedback">Fullname is required</div>    
                                        </div>
                                        <div class="form-group col-md-6 mt-3">
                                            <label >Phone number</label>
                                            <input type="text" class="form-control"
                                                placeholder="Enter data..." v-model="form.phone" :class="{ 'is-invalid': submitted && $v.form.phone.$error }">
                                            <div v-if="submitted && !$v.form.phone.required" class="invalid-feedback">Phone number is required</div>    
                                            <div v-if="submitted && !$v.form.phone.numeric" class="invalid-feedback"> Phone number must be a number</div>
                                        </div>

                                        <div class="form-group col-md-6 mt-3">
                                            <label >Email</label>
                                            <input type="text" class="form-control"
                                                placeholder="Enter data..." v-model="form.email" :class="{ 'is-invalid': submitted && $v.form.email.$error }">
                                            <div v-if="submitted && !$v.form.email.required" class="invalid-feedback">Email is required</div>    
                                        </div>
                                    </div>
                                    <div class="button-items mt-3">
                                        <button type="submit" class="btn btn-primary" :disabled="waiting">
                                        <i class="fa fa-save"></i> {{ waiting ? 'Please wait...' : 'Save changes' }}</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        required, numeric
    } from "vuelidate/lib/validators";
    import {id} from 'vuejs-datepicker/dist/locale';
    import moment from 'moment';
    
    export default {
        name: 'account_profile',
        metaInfo: {
            title: 'Profile',
            titleTemplate: '%s - Tata Hijau Asri'
        },
        created() {
            this.getData();
        },
        data() {
            return {
                id: id,
                label           : 'Profile',
                form : {
                    id              : "",
                    username        : "",
                    phone           : "",
                    identity_type   : "",
                    identity_number   : "",
                    fullname          : "",
                    place_birth : "",
                    date_birth  : moment().format('YYYY-MM-DD'),
                    gender      : "",
                    religion    : "",
                    email       : "",
                    address     : "",
                    photo  : "",
                    previousphoto  : ""                   
                },
                preview_image   : '',
                dataFetch       : true,
                submitted       : false,
                waiting         : false
            }
        },
        validations: {
            form: {
                username: {
                    required
                },
                fullname: {
                    required
                },
                phone: {
                    required,
                    numeric
                },
                email: {
                    required
                },
            }
        },
        methods: {
            onSubmit: function () {
                this.waiting    = true;
                this.submitted  = true;

                // stop here if form is invalid
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Please complete the form',
                    });

                    this.waiting = false;
                    return;
                } else {    
                    let formData = new FormData();

                    formData.append('id', this.form.id);
                    formData.append('username', this.form.username);
                    formData.append('identity_type', this.form.identity_type);
                    formData.append('identity_number', this.form.identity_number);
                    formData.append('fullname', this.form.fullname);
                    formData.append('phone', this.form.phone);
                    formData.append('place_birth', this.form.place_birth);
                    formData.append('date_birth', this.convertDate(this.form.date_birth));
                    formData.append('gender', this.form.gender);
                    formData.append('religion', this.form.religion);
                    formData.append('email', this.form.email);
                    formData.append('address', this.form.address);
                    formData.append('photo', this.form.photo);

                    this.$http.post(process.env.VUE_APP_APIHOST + 'user/profile/save', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then(response => {
                            if (response.data.status) {
                                this.$swal({
                                    icon: 'success',
                                    title: 'Success',
                                    text: response.data.message,
                                    confirmButtonText: 'OK'
                                }).then((result) => {
                                    if (result.value) {
                                        this.getData();
                                    }
                                });
                            } else {
                                this.$swal({
                                    icon: 'warning',
                                    title: 'Ups!',
                                    text: response.data.message,
                                });
                            }
                            this.waiting = false;
                        })
                    .catch(function () {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: 'Please check your internet connection.',
                        });
                        this.waiting = false;
                        return;
                    });

                }
            },
            getData: function() {
                let uri     = process.env.VUE_APP_APIHOST+'user/profile';

                this.dataFetch  = true;                
                this.$http.get(uri).then(res => {
                    if(res.data.status) {
                        let getResponse = res.data.data;

                        this.form.id                = getResponse.id;
                        this.form.username          = getResponse.username;
                        this.form.identity_type     = getResponse.identity_type;
                        this.form.identity_number   = getResponse.identity_number;
                        this.form.phone             = getResponse.phone;
                        this.form.fullname          = getResponse.fullname;
                        this.form.place_birth       = getResponse.place_birth;
                        this.form.date_birth        = getResponse.date_birth;
                        this.form.gender            = getResponse.gender;
                        this.form.religion          = getResponse.religion;
                        this.form.email             = getResponse.email;
                        this.form.address           = getResponse.address;
                        this.form.photo             = getResponse.photo;
                        this.preview_image          = getResponse.photo;
                    } else {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: res.data.message,
                            confirmButtonText: 'OK'
                        }).then((result) => {
                            if (result.value) {
                                this.getData();
                            }
                        });
                    }
                    this.dataFetch  = false;
                });
            },
            convertDate : function (date) {
                return moment(date, 'YYYY-MM-DD').format('yyyy-MM-DD');
            },
            previewFiles(e) {
                const file = e.target.files[0];                
                var fsize = ((file.size/1024)/1024).toFixed(4); // MB
                
                if(fsize >= 2) {
                    alert("Uploaded files cannot be more than 2 MB");
                } else {
                    this.preview_image = URL.createObjectURL(file);
                    this.form.photo = file;
                }
            }
        }
    }
</script>