<template>
    <div>
        <div class="page-content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                            <h4 class="mb-sm-0">Welcome to Content Management System (CMS) - Tata Hijau Asri</h4>
                        </div>

                        <div v-if="dataFetch" class="text-center">
                            <p>Loading...</p>
                        </div>

                        <div v-if="!dataFetch" class="row">
                            <div class="col-xl-4 col-md-12 mb-2">
                                <div class="card-dashboard bg-c-green border-left-primary shadow h-100 py-2">
                                    <div class="card-body" v-on:click="goToView('team')">
                                        <div class="row align-items-center">
                                            <div class="col">
                                                <div
                                                    class="order-card text-xs font-weight-bold text-uppercase mb-2">
                                                    Total Team</div>
                                                <div class="h5 mb-0 order-card">{{ dashboard.total_team }}</div>
                                            </div>
                                            <div class="col-auto">
                                                <i class="fas fa-users fa-2x text-white"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-xl-4 col-md-12 mb-2">
                                <div class="card-dashboard bg-c-blue border-left-primary shadow h-100 py-2">
                                    <div class="card-body" v-on:click="goToView('works_construction')">
                                        <div class="row align-items-center">
                                            <div class="col">
                                                <div
                                                    class="order-card text-xs font-weight-bold text-uppercase mb-2">
                                                    Total Construction</div>
                                                <div class="h5 mb-0 order-card">{{ dashboard.total_construction }}</div>
                                            </div>
                                            <div class="col-auto">
                                                <i class="fas fa-list fa-2x text-white"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-xl-4 col-md-12 mb-2">
                                <div class="card-dashboard bg-c-yellow border-left-primary shadow h-100 py-2">
                                    <div class="card-body" v-on:click="goToView('works_maintenance')">
                                        <div class="row align-items-center">
                                            <div class="col">
                                                <div
                                                    class="order-card text-xs font-weight-bold text-uppercase mb-2">
                                                    Total Maintenance</div>
                                                <div class="h5 mb-0 order-card">{{ dashboard.total_maintenance }}</div>
                                            </div>
                                            <div class="col-auto">
                                                <i class="fas fa-database fa-2x text-white"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Dashboard',
        metaInfo: {
            title: 'Dashboard',
            titleTemplate: '%s - Tata Hijau Asri'
        },
        data() {
            return {
                label     : 'Dashboard',
                dataFetch : false,
                dashboard : []
            }
        },
        created() {
            this.getData();
        },
        methods: {
            getData: function() {
                let uri         = process.env.VUE_APP_APIHOST+'admin/general/dashboard_total';

                this.dataFetch  = true;                
                this.$http.get(uri).then(res => {
                    this.dataFetch  = false;                
                    this.dashboard  = res.data.data;
                });
            },
            goToView: function(params="") {
                this.$router.push({ name: params });
            }
        }
    }
</script>