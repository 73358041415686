import Vue from 'vue'
import VueRouter from 'vue-router'
import Axios from 'axios'
import VueBodyClass from 'vue-body-class';

import Signin from '../views/authentication/Signin.vue'
import Signout from '../views/authentication/Signout.vue'
import MainMenu from '@/layouts/MainMenu.vue';

import Dashboard from '../views/static/Dashboard.vue'
import Maintenance from '../views/static/Maintenance.vue'
import NotFound from '../views/static/NotFound.vue'

// Team Routes
import TeamMain from '../views/administrator/team/Main.vue'
import TeamForm from '../views/administrator/team/Form.vue'

// Banner Routes
import BannerMain from '../views/administrator/banner/Main.vue'
import BannerForm from '../views/administrator/banner/Form.vue'

// WorksConstruction Routes
import WorksConstructionMain from '../views/administrator/works-construction/Main.vue'
import WorksConstructionForm from '../views/administrator/works-construction/Form.vue'

// WorksMaintenance Routes
import WorksMaintenanceMain from '../views/administrator/works-maintenance/Main.vue'
import WorksMaintenanceForm from '../views/administrator/works-maintenance/Form.vue'

// Services Routes
import ServicesMain from '../views/administrator/services/Main.vue'
import ServicesForm from '../views/administrator/services/Form.vue'

// Users Routes
import Profile from '../views/static/Profile.vue'

// identity Routes
import Contacts from '../views/administrator/identity/Contacts.vue'
import AboutPage from '../views/administrator/identity/AboutPage.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Dashboard,
    meta: {
      transition : 'fade',
      layout : MainMenu,
      roles:['administrator']
    }
  },
  {
    path: '/maintenance',
    name: 'maintenance',
    component: Maintenance,
    meta: {
      transition : 'fade',
      layout : MainMenu,
      roles:['administrator']
    }
  },
  {
    path: '*',
    name: '404',
    component: NotFound,
    meta: {
      transition : 'fade',
      layout : MainMenu,
      roles:['administrator']
    }
  },
  {
    path: '/signin',
    name: 'signin',
    component: Signin,
    meta: {
      transition  : 'fade'
    }
  },
  {
    path: '/signout',
    name: 'signout',
    component: Signout,
    meta: {
      transition  : 'fade',
    }
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard,
    meta: {
      transition : 'fade',
      layout : MainMenu,
      roles:['administrator']
    }
  },
  {
    path: '/team',
    name: 'team',
    component: TeamMain,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['administrator']
    }
  },
  {
    path: '/team/add',
    name: 'team_add',
    component: TeamForm,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['administrator']
    }
  },
  {
    path: '/team/edit/:id',
    name: 'team_edit',
    component: TeamForm,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['administrator']
    }
  },
  {
    path: '/banner',
    name: 'banner',
    component: BannerMain,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['administrator']
    }
  },
  {
    path: '/banner/add',
    name: 'banner_add',
    component: BannerForm,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['administrator']
    }
  },
  {
    path: '/banner/edit/:id',
    name: 'banner_edit',
    component: BannerForm,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['administrator']
    }
  },
  {
    path: '/profile',
    name: 'profile',
    component: Profile,
    meta: {
      transition : 'fade',
      layout : MainMenu,
      roles:['administrator']
    }
  },
  {
    path: '/contacts',
    name: 'contacts',
    component: Contacts,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['administrator']
    }
  },
  {
    path: '/about-us',
    name: 'about_us',
    component: AboutPage,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['administrator']
    }
  },
  {
    path: '/services',
    name: 'services',
    component: ServicesMain,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['administrator']
    }
  },
  {
    path: '/services/edit/:id',
    name: 'services_edit',
    component: ServicesForm,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['administrator']
    }
  },
  {
    path: '/works-construction',
    name: 'works_construction',
    component: WorksConstructionMain,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['administrator']
    }
  },
  {
    path: '/works-construction/add',
    name: 'works_construction_add',
    component: WorksConstructionForm,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['administrator']
    }
  },
  {
    path: '/works-construction/edit/:id',
    name: 'works_construction_edit',
    component: WorksConstructionForm,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['administrator']
    }
  },
  {
    path: '/works-maintenance',
    name: 'works_maintenance',
    component: WorksMaintenanceMain,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['administrator']
    }
  },
  {
    path: '/works-maintenance/add',
    name: 'works_maintenance_add',
    component: WorksMaintenanceForm,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['administrator']
    }
  },
  {
    path: '/works-maintenance/edit/:id',
    name: 'works_maintenance_edit',
    component: WorksMaintenanceForm,
    meta: {
      transition: 'fade',
      layout: MainMenu,
      roles:['administrator']
    }
  }
]

const vueBodyClass = new VueBodyClass(routes);

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages   = ['signin'];
  const authRequired  = !publicPages.includes(to.name);
  const token         = localStorage.getItem('auth_token');

  if (authRequired && token) {
      Axios.get(process.env.VUE_APP_APIHOST + 'user/info', {
          headers: {
              'Authorization': token
          }
      }).then(response => {
        if(response.data.logged_in) {
          let roles = response.data.role;
                  
          if(to.meta.roles.includes(roles)) {
              next();
          } else {
              alert("Akses tidak diperbolehkan");
              next({ name: 'dashboard' });
          }
        } else {
          alert("Sesi login telah habis !");
          localStorage.removeItem('id');
          localStorage.removeItem('auth_token');
          next({ name: 'signin' });
        }
    }).catch(function (error) {
        console.debug(error);
    });
  }

  else if (authRequired && !token) {
    next({ name: 'signin' });
  }

  else {
    if(to.name == 'signin' || to.name == 'signup') {
        if(token) {
            next({ name: 'dashboard' });
        } else {
            next();
        }
    }
    else {
        if(!token) {
            if(to.name == 'signout') {
                next({ name: 'signin' });
            } else {
                next();
            }
        } else {
            next();
        }
    }
  }

  vueBodyClass.guard(to, next);
});


export default router
