<template>
    <div>
        <div class="page-content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-body">
                                <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 class="mb-sm-0">{{this.label}} Data Construction</h4>
                                    <div class="page-title-right">
                                        <ol class="breadcrumb m-0">
                                            <li class="breadcrumb-item"><router-link :to="{name : 'works_construction'}">Data Construction</router-link></li>
                                            <li class="breadcrumb-item active">Form</li>
                                        </ol>
                                    </div>
                                </div>

                                <div v-if="dataFetch">
                                    <p>Loading...</p>
                                </div>

                                <form v-if="!dataFetch" v-on:submit.prevent="onSubmit">
                                    <div class="row">
                                        <input type="hidden" name="id" v-model="form.id">
                                        <div class="form-group col-md-6 mt-3">
                                            <label>Name</label>
                                            <input type="text" class="form-control"
                                                placeholder="Enter name..." v-model="form.name" :class="{ 'is-invalid': submitted && $v.form.name.$error }">
                                            <div v-if="submitted && !$v.form.name.required" class="invalid-feedback">Name is required</div>
                                        </div>

                                        <div class="form-group col-md-6 mt-3">
                                            <label>Location</label>
                                            <input type="text" class="form-control" placeholder="Enter location..." v-model="form.location">
                                        </div>
                                        
                                        <div class="form-group col-md-6 mt-3">
                                            <label>Client</label>
                                            <input type="text" class="form-control" placeholder="Enter client..." v-model="form.client">
                                        </div>

                                        <div class="form-group col-md-6 mt-3">
                                            <label>Transaction Type</label>
                                            <input type="text" class="form-control" placeholder="Enter type..." v-model="form.transaction_type">
                                        </div>

                                        <div class="form-group col-md-6 mt-3">
                                            <label>Transaction Status</label>
                                            <input type="text" class="form-control" placeholder="Enter status..." v-model="form.transaction_status">
                                        </div>

                                        <div class="form-group col-md-6 mt-3">
                                            <label for="exampleFormControlSelect1">Status</label>
                                            <select class="form-select" v-model="form.status">
                                                <option value="publish">Publish</option>
                                                <option value="draft">Draft</option>
                                            </select>
                                        </div>
                                        
                                        <div class="form-group col-md-6 mt-3">
                                            <label>Thumbnail</label>
                                            <input type="file" class="form-control" name="thumbnail" id="thumbnail" @change="previewThumbnail" accept="image/png, image/gif, image/jpeg">
                                        </div>

                                        <div class="form-group col-md-6 mt-3" v-if="preview_thumbnail">
                                            <label>Preview Thumbnail</label>
                                            <div class="thumbnail mb-2">
                                                <div class="thumb">
                                                    <a :href="preview_thumbnail" data-lightbox="1" target="blank">
                                                        <img :src="preview_thumbnail" alt="" class="img-fluid img-thumbnail" style="width:50%">
                                                    </a>
                                                </div>
                                            </div>
                                            <button class="btn btn-danger btn-sm" type="button" v-on:click="removeBanner('thumbnail')"><i class="fa fa-trash"></i> Remove Thumbnail</button>
                                        </div>

                                        <div class="form-group col-md-6 mt-3">
                                            <label>Banner One</label>
                                            <input type="file" class="form-control" name="banner_one" id="banner_one" @change="previewBannerOne" accept="image/png, image/gif, image/jpeg">
                                        </div>

                                        <div class="form-group col-md-6 mt-3" v-if="preview_banner_one">
                                            <label>Preview Banner One</label>
                                            <div class="thumbnail mb-2">
                                                <div class="thumb">
                                                    <a :href="preview_banner_one" data-lightbox="1" target="blank">
                                                        <img :src="preview_banner_one" alt="" class="img-fluid img-thumbnail" style="width:50%">
                                                    </a>
                                                </div>
                                            </div>
                                            <button class="btn btn-danger btn-sm" type="button" v-on:click="removeBanner('one')"><i class="fa fa-trash"></i> Remove Banner One</button>
                                        </div>

                                        <div class="form-group col-md-6 mt-3">
                                            <label>Banner Two</label>
                                            <input type="file" class="form-control" name="banner_two" id="banner_two" @change="previewBannerTwo" accept="image/png, image/gif, image/jpeg">
                                        </div>

                                        <div class="form-group col-md-6 mt-3" v-if="preview_banner_two">
                                            <label>Preview Banner Two</label>
                                            <div class="thumbnail mb-2">
                                                <div class="thumb">
                                                    <a :href="preview_banner_two" data-lightbox="1" target="blank">
                                                        <img :src="preview_banner_two" alt="" class="img-fluid img-thumbnail" style="width:50%">
                                                    </a>
                                                </div>
                                            </div>
                                            <button class="btn btn-danger btn-sm mt-2" type="button" v-on:click="removeBanner('two')"><i class="fa fa-trash"></i> Remove Banner Two</button>
                                        </div>

                                        <div class="form-group col-md-6 mt-3">
                                            <label>Banner Three</label>
                                            <input type="file" class="form-control" name="banner_three" id="banner_three" @change="previewBannerThree" accept="image/png, image/gif, image/jpeg">
                                        </div>

                                        <div class="form-group col-md-6 mt-3" v-if="preview_banner_three">
                                            <label>Preview Banner Three</label>
                                            <div class="thumbnail mb-2">
                                                <div class="thumb">
                                                    <a :href="preview_banner_three" data-lightbox="1" target="blank">
                                                        <img :src="preview_banner_three" alt="" class="img-fluid img-thumbnail" style="width:50%">
                                                    </a>
                                                </div>
                                            </div>
                                            <button class="btn btn-danger btn-sm mt-2" type="button" v-on:click="removeBanner('three')"><i class="fa fa-trash"></i> Remove Banner Three</button>
                                        </div>

                                        <div class="form-group col-md-6 mt-3">
                                            <label>Banner Four</label>
                                            <input type="file" class="form-control" name="banner_four" id="banner_four" @change="previewBannerFour" accept="image/png, image/gif, image/jpeg">
                                        </div>

                                        <div class="form-group col-md-6 mt-3" v-if="preview_banner_four">
                                            <label>Preview Banner Four</label>
                                            <div class="thumbnail mb-2">
                                                <div class="thumb">
                                                    <a :href="preview_banner_four" data-lightbox="1" target="blank">
                                                        <img :src="preview_banner_four" alt="" class="img-fluid img-thumbnail" style="width:50%">
                                                    </a>
                                                </div>
                                            </div>
                                            <button class="btn btn-danger btn-sm mt-2" type="button" v-on:click="removeBanner('four')"><i class="fa fa-trash"></i> Remove Banner Four</button>
                                        </div>
                                    </div>
                                    <div class="button-items mt-3">
                                        <button type="submit" class="btn btn-primary" :disabled="waiting">
                                        <i class="fa fa-save"></i> {{ waiting ? 'Please wait...' : 'Save' }}</button>
                                        <router-link :to="{name : 'works_construction'}" class="btn btn-outline-primary">Back</router-link>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        required
    } from "vuelidate/lib/validators";
    
    export default {
        name: 'works_construction',
        metaInfo: {
            title: 'Form Construction',
            titleTemplate: '%s - Tata Hijau Asri'
        },
        created() {
            this.getDataByID(this.$route.params.id);
        },
        data() {
            return {
                label           : 'Add',
                form : {
                    id                  : "",
                    name                : "",
                    location            : "",
                    client              : "",
                    transaction_type    : "",
                    transaction_status  : "",
                    status              : "draft",
                    thumbnail              : "",
                    previous_thumbnail     : "" ,
                    banner_one             : "",
                    previous_banner_one    : "" ,
                    banner_two             : "",
                    previous_banner_two    : "" ,                  
                    banner_three           : "",
                    previous_banner_three  : "",
                    banner_four           : "",
                    previous_banner_four  : "",
                },
                preview_thumbnail   : '',
                preview_banner_one   : '',
                preview_banner_two   : '',
                preview_banner_three   : '',
                preview_banner_four   : '',
                dataFetch       : false,
                submitted       : false,
                waiting         : false
            }
        },
        validations: {
            form: {
                name: {
                    required
                }
            }
        },
        methods: {
            onSubmit: function () {
                this.waiting    = true;
                this.submitted  = true;

                // stop here if form is invalid
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Please complete the form',
                    });

                    this.waiting = false;
                    return;
                } else {    
                    let formData = new FormData();

                    formData.append('id', this.form.id);
                    formData.append('name', this.form.name);
                    formData.append('location', this.form.location);
                    formData.append('client', this.form.client);
                    formData.append('transaction_type', this.form.transaction_type);
                    formData.append('transaction_status', this.form.transaction_status);
                    formData.append('status', this.form.status);
                    formData.append('thumbnail', this.form.thumbnail);
                    formData.append('banner_one', this.form.banner_one);
                    formData.append('banner_two', this.form.banner_two);
                    formData.append('banner_three', this.form.banner_three);
                    formData.append('banner_four', this.form.banner_four);
                    formData.append('works_type_id', 1);

                    this.$http.post(process.env.VUE_APP_APIHOST + 'admin/works/save', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then(response => {
                            if (response.data.status) {
                                this.$swal({
                                    icon: 'success',
                                    title: 'Success',
                                    text: response.data.message,
                                    confirmButtonText: 'OK'
                                }).then((result) => {
                                    if (result.value) {
                                        this.$router.push({ name: 'works_construction' });
                                    }
                                });
                            } else {
                                this.$swal({
                                    icon: 'warning',
                                    title: 'Ups!',
                                    text: response.data.message,
                                });
                            }
                            this.waiting = false;
                        })
                    .catch(function () {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: 'Please check your internet connection.',
                        });
                        this.waiting = false;
                        return;
                    });

                }
            },
            getDataByID: function(id) {
                if (typeof (id) !== 'undefined' && id !== null) {
                    this.label  = "Edit";

                    let uri     = process.env.VUE_APP_APIHOST+'admin/works/detail';

                    this.dataFetch  = true;                
                    this.$http.get(uri,{
                        params : {
                            id : id
                        }
                    }).then(res => {
                        if(res.data.status) {
                            let getResponse = res.data.data;

                            this.form.id        = getResponse.id;
                            this.form.name      = getResponse.name;
                            this.form.location  = getResponse.location;
                            this.form.client    = getResponse.client;
                            this.form.transaction_type      = getResponse.transaction_type;
                            this.form.transaction_status    = getResponse.transaction_status;
                            this.form.status                = getResponse.status;

                            this.form.thumbnail        = getResponse.thumbnail;
                            this.preview_thumbnail     = getResponse.thumbnail;
                            this.form.banner_one        = getResponse.banner_one;
                            this.preview_banner_one     = getResponse.banner_one;
                            this.form.banner_two        = getResponse.banner_two;
                            this.preview_banner_two     = getResponse.banner_two;
                            this.form.banner_three      = getResponse.banner_three;
                            this.preview_banner_three   = getResponse.banner_three;
                            this.form.banner_four       = getResponse.banner_four;
                            this.preview_banner_four    = getResponse.banner_four;

                            this.dataFetch = false;
                        } else {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: res.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    this.$router.push({ name: 'works_construction' });
                                }
                            });
                        }
                    });
                }
            },
            previewThumbnail(e) {
                const file = e.target.files[0];                
                var fsize = ((file.size/1024)/1024).toFixed(4); // MB
                
                if(fsize >= 2) {
                    alert("Uploaded files cannot be more than 2 MB");
                } else {
                    this.preview_thumbnail = URL.createObjectURL(file);
                    this.form.thumbnail = file;
                }
            },
            previewBannerOne(e) {
                const file = e.target.files[0];                
                var fsize = ((file.size/1024)/1024).toFixed(4); // MB
                
                if(fsize >= 2) {
                    alert("Uploaded files cannot be more than 2 MB");
                } else {
                    this.preview_banner_one = URL.createObjectURL(file);
                    this.form.banner_one = file;
                }
            },
            previewBannerTwo(e) {
                const file = e.target.files[0];                
                var fsize = ((file.size/1024)/1024).toFixed(4); // MB
                
                if(fsize >= 2) {
                    alert("Uploaded files cannot be more than 2 MB");
                } else {
                    this.preview_banner_two = URL.createObjectURL(file);
                    this.form.banner_two = file;
                }
            },
            previewBannerThree(e) {
                const file = e.target.files[0];                
                var fsize = ((file.size/1024)/1024).toFixed(4); // MB
                
                if(fsize >= 2) {
                    alert("Uploaded files cannot be more than 2 MB");
                } else {
                    this.preview_banner_three = URL.createObjectURL(file);
                    this.form.banner_three = file;
                }
            },
            previewBannerFour(e) {
                const file = e.target.files[0];                
                var fsize = ((file.size/1024)/1024).toFixed(4); // MB
                
                if(fsize >= 2) {
                    alert("Uploaded files cannot be more than 2 MB");
                } else {
                    this.preview_banner_four = URL.createObjectURL(file);
                    this.form.banner_four = file;
                }
            },
            removeBanner: function(params="") {
                this.$swal({
                    icon: 'info',
                    title: 'Remove banner '+params,
                    text: 'Are you sure you want to remove this banner?',
                    confirmButtonText: 'Remove',
                    showCancelButton: true,
                    cancelButtonText: 'Cancel',
                }).then((result) => {
                if (result.value) {
                    if(params == 'one') {
                        this.preview_banner_one = '';
                        this.form.banner_one = '';
                    }

                    if(params == 'two') {
                        this.preview_banner_two = '';
                        this.form.banner_two = '';
                    }

                    if(params == 'three') {
                        this.preview_banner_three = '';
                        this.form.banner_three = '';
                    }

                    if(params == 'four') {
                        this.preview_banner_four = '';
                        this.form.banner_four = '';
                    }

                    if(params == 'thumbnail') {
                        this.preview_thumbnail = '';
                        this.form.thumbnail = '';
                    }
                }
                });
            }
        }
    }
</script>