<template>
    <div>
        <div class="page-content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-body">
                                <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 class="mb-sm-0">Data Team</h4>

                                    <div class="page-title-right">
                                        <router-link class="btn btn-primary rounded" :to="{name : 'team_add'}"><i class="fa fa-plus"></i> Add Data</router-link>
                                    </div>
                                </div>
                                <div class="input-group">
                                    <input type="text" autocomplete="off" class="form-control" aria-describedby="emailHelp" placeholder="Search data by keyword..." v-model="keywords" v-on:keyup.enter="getData">
                                    <div class="input-group-append">
                                        <button v-on:click="getData" class="btn waves-effect waves-light btn-primary btn-icon btn-msg-send" type="button"><i
                                                class="fa fa-search"></i> Search </button>
                                    </div>
                                </div>
                                <hr/>
                                <div v-if="dataFetch">
                                    <p>Loading...</p>
                                </div>

                                <div v-if="!dataFetch">
                                    <div v-if="dataList.length > 0" class="table-responsive mt-3">
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th style="width:5%" class="text-center">No</th>
                                                    <th style="width:25%">Name</th>
                                                    <th style="width:10%" class="text-center">Picture</th>
                                                    <!-- <th style="width:5%" class="text-center">Status</th> -->
                                                    <th class="text-center" style="width:10%">Aksi</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(item, index) in dataList" :key="index">
                                                    <td class="text-center">
                                                        {{ (index + 1) + (pagination.currentPage - 1) * pagination.limit }}
                                                    </td>
                                                    <td>
                                                        {{item.name}}<br/>
                                                        Position : <b>{{ item.position_name }}</b>
                                                    </td>
                                                    <td class="text-center">
                                                        <small v-if="item.picture">
                                                            <a target="blank" :href="item.picture"><img id="foto_team" style="width:20%;border: 1px solid #ddd;border-radius: 4px;padding: 5px;width: 70px;" :src="item.picture"></a>
                                                        </small>
                                                        <span v-if="!item.picture">-</span>
                                                    </td>
                                                    <td class="text-center d-none">
                                                        <span v-if="item.status == 'draft'" class="badge bg-secondary">Draft</span>
                                                        <span v-if="item.status == 'publish'" class="badge bg-primary">Publish</span>
                                                    </td>
                                                    <td class="text-center">
                                                        <div class="button-items">
                                                            <router-link :to="{name : 'team_edit', params: {id:item.id}}" type="button" class="btn btn-primary btn-sm rounded"><i class="fas fa-edit"></i> Edit</router-link>
                                                            <button v-on:click="deleteData(item.id)" type="button" class="btn btn-danger btn-sm rounded"><i class="fas fa-trash"></i> Delete</button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>                                        
                                        <hr/>
                                        <nav aria-label="navigation" class="m-t-20">
                                            <v-pagination v-model="pagination.currentPage" :page-count="pagination.totalPages" :classes="pagination.bootstrapPaginationClasses" :labels="pagination.paginationAnchorTexts" v-on:change="getData()"></v-pagination>
                                        </nav>
                                    </div>

                                    <div v-if="emptyState">
                                        <empty-state></empty-state>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import vPagination from 'vue-plain-pagination'

    export default {
        name: 'Team',
        metaInfo: {
            title: 'Data Team',
            titleTemplate: '%s - Tata Hijau Asri'
        },
        components: {
            vPagination
        },
        data() {
            return {
                emptyState  : false,
                dataFetch   : false,
                dataList    : [],
                keywords    : "",
                pagination: {
                    currentPage : 1,
                    totalPages  : 0,
                    limit       : 10,
                    bootstrapPaginationClasses: {
                        ul: 'pagination justify-content-end',
                        li: 'page-item',
                        liActive: 'active',
                        liDisable: 'disabled',
                        button: 'page-link'
                    },
                    paginationAnchorTexts: {
                        first: 'First',
                        prev: 'Previous',
                        next: 'Next',
                        last: 'Last'
                    }
                }
            }
        },
        created() {
            this.getData();
        },
        methods: {
            getData: function() {
                let uri         = process.env.VUE_APP_APIHOST+'admin/team/list';

                this.dataFetch  = true;                
                this.$http.get(uri,{
                    params : {
                        keywords : this.keywords,
                        page     : this.pagination.currentPage,
                        limit    : this.pagination.limit
                    }
                }).then(res => {
                    this.emptyState             = false;
                    this.dataFetch              = false;                
                    this.dataList               = res.data.data;
                    this.pagination.totalPages  = res.data.total_page;

                    if(this.dataList.length < 1) {
                        this.emptyState = true;
                    }
                });
            },
            deleteData: function (id) {
                this.$swal({
                    icon: 'info',
                    title: 'Hapus Data',
                    text: 'Apakah kamu yakin ingin menghapus data ini?',
                    confirmButtonText: 'Delete',
                    showCancelButton: true,
                    cancelButtonText: 'Cancel',
                }).then((result) => {
                if (result.value) {
                    this.$http.post(process.env.VUE_APP_APIHOST + 'admin/team/delete', {
                        id: id
                    })
                    .then(response => {
                        if (response.data.status) {
                            this.$swal({
                                icon: 'success',
                                title: 'Success',
                                text: response.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    this.getData();
                                }
                            });
                        } else {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: response.data.message,
                        });
                        }
                    })
                }
                });
            }
        }
    }
</script>