<template>
    <div>
        <div id="layout-wrapper">

            
            <header id="page-topbar">
                <div class="navbar-header">
                    <div class="d-flex">
                        <!-- LOGO -->
                        <div class="navbar-brand-box text-center">
                            <router-link :to="{name : 'dashboard'}" class="logo logo-dark">
                                <span class="logo-sm">
                                    <img src="images/logo-sm.png" alt="logo-sm-dark" height="22">
                                </span>
                                <span class="logo-lg">
                                    <img src="images/logo-dark.png" alt="logo-dark" height="24">
                                </span>
                            </router-link>

                            <router-link :to="{name : 'dashboard'}" class="logo logo-light">
                                <span class="logo-sm">
                                    <img src="images/favicon/favicon.ico" alt="logo-sm-light" height="22">

                                </span>
                                <span class="logo-lg">
                                    <img src="images/logo-navigation.png" alt="logo-light" height="38">
                                </span>
                            </router-link>
                        </div>

                        <button type="button" class="btn btn-sm px-3 font-size-24 header-item waves-effect" id="vertical-menu-btn">
                            <i class="ri-menu-2-line align-middle"></i>
                        </button>
                    </div>

                    <div class="d-flex">
                        <div class="dropdown d-inline-block user-dropdown">
                            <button type="button" class="btn header-item waves-effect" id="page-header-user-dropdown"
                                data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <img class="rounded-circle header-profile-user" src="images/users/default.png"
                                    alt="Header Avatar">
                                <span class="d-none d-xl-inline-block ms-1">{{users.name}}</span>
                                <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
                            </button>
                            <div class="dropdown-menu dropdown-menu-end">
                                <!-- item-->
                                <router-link class="dropdown-item" :to="{name : 'profile'}"><i class="ri-user-line align-middle me-1"></i> Profile</router-link>
                                <a data-toggle="modal" data-target="#modalPasswordUsers" href="javascript:void(0)" class="dropdown-item"><i class="ri-lock-unlock-line align-middle me-1"></i> Change Password</a>
                                <div class="dropdown-divider"></div>
                                <router-link :to="{name : 'signout'}" class="dropdown-item text-danger"><i class="ri-shut-down-line align-middle me-1 text-danger"></i> Logout</router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </header>

            <!-- ========== Left Sidebar Start ========== -->
            <div class="vertical-menu">

                <div data-simplebar class="h-100">

                    <!--- Sidemenu -->
                    <div id="sidebar-menu">
                        <ul v-for="(item, index) in dataMenu" :key="index" class="metismenu list-unstyled" id="side-menu">
                            <li class="menu-title">
                                {{index}}
                            </li>
                            

                            <li v-for="(menu, indexMenu) in item" :key="indexMenu" :class="menu.parent == 1 ? menu.url.includes(activeClass) ? 'mm-active': '' : activeClass == menu.url ? 'mm-active':''" v-on:click="setCurrent(menu.url)">
                                <router-link v-if="menu.parent != 1" :to="{name : menu.url}" class="waves-effect">
                                    <i :class="menu.icon"></i>
                                    <span>{{menu.name}}</span>
                                </router-link>

                                <a v-if="menu.parent == 1" href="javascript: void(0);" class="has-arrow waves-effect">
                                    <i :class="menu.icon"></i>
                                    <span>{{menu.name}}</span>
                                </a>
                                <ul v-if="menu.parent == 1" class="sub-menu" aria-expanded="false">
                                    <li v-for="(subMenu, indexSubMenu) in menu.sub_menu" :key="indexSubMenu" v-on:click="setCurrent(subMenu.url)">
                                        <router-link :to="{name : subMenu.url}" :class="activeClass == subMenu.url ? 'active':''">{{subMenu.name}}</router-link>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                    <!-- Sidebar -->
                </div>
            </div>
            
            <div class="main-content">
                <slot/>
            </div>
        </div>

        <!-- Modal -->
        <div class="modal" id="modalPasswordUsers" tabindex="-1" aria-hidden="true" data-backdrop="static">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content" style="overflow: hidden;">
                    <div class="d-block px-3 pt-3 pb-0">
                        <div class="d-flex align-items-center justify-content-between text-sm">
                            <div class="font-weight-600" style="font-size:20px"><i class="fa fa-edit"></i> Change Password</div>
                            <div>
                                <button id="btnCloseModal" type="button" class="btn btn-link btn-sm p-0 text-primary"
                                    data-dismiss="modal">Close</button>
                            </div>
                        </div>
                    </div>
                    

                    <form v-on:submit.prevent="onSubmit">
                        <div class="d-block p-3">
                            <div class="d-flex align-items-center pb-3 row">
                                <div class="col-md-12">
                                    <div class="mt-3">
                                        <label>Old password</label>
                                        <input type="password" autocomplete="off" class="form-control" aria-describedby="emailHelp" placeholder="Enter New Password..." v-model="form.old_password"
                                        :class="{ 'is-invalid': submitted && $v.form.old_password.$error }"
                                        >

                                        <div v-if="submitted && !$v.form.old_password.required" class="invalid-feedback d-block">
                                        Old password is required</div>
                                    </div>

                                    <div class="mt-3">
                                        <label>New Password</label>
                                        <input type="password" autocomplete="off" class="form-control" aria-describedby="emailHelp" placeholder="Enter New Password..." v-model="form.new_password"
                                        :class="{ 'is-invalid': submitted && $v.form.new_password.$error }"
                                        >

                                        <div v-if="submitted && !$v.form.new_password.required" class="invalid-feedback d-block">
                                        New Password is required</div>
                                    </div>

                                    <div class="mt-3">
                                        <label>Repeat New Password</label>
                                        <input type="password" autocomplete="off" class="form-control" aria-describedby="emailHelp" placeholder="Repeat New Password..." v-model="form.retype_new_password"
                                        :class="{ 'is-invalid': submitted && $v.form.retype_new_password.$error }"
                                        >

                                        <div v-if="submitted && !$v.form.retype_new_password.required" class="invalid-feedback d-block">
                                        Repeat password is required</div>
                                    </div>

                                    <hr/>
                                    <div class="mt-3">
                                        <button :disabled="waiting" type="submit" class="btn btn-primary btn-block"><i class="fas fa-save"></i> {{ waiting ? 'Please wait...' : 'Save changes' }}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div> 
            </div>
        </div>
    </div>
</template>

<script>
    import {
        required
    } from "vuelidate/lib/validators";

    export default {
        name: 'Main',
        data() {
            return {
                activeClass     : this.$route.name,
                activeMenu      : "",
                activeSubMenu   : "",
                lastMenu        : "",
                dataMenu        : [],
                users           : [],
                form : {
                    old_password        : '',
                    new_password        : '',
                    retype_new_password : '',
                },
                submitted   : false,
                waiting     : false
            }
        },
        validations: {
            form: {
                old_password: {
                    required
                },
                new_password: {
                    required
                },
                retype_new_password: {
                    required
                },
            }
        },
        created() {
            this.getMenu();
            this.getUsers();
        },
        methods: {
            setCurrent: function(params = "") {
                this.activeClass    = params;
            },
            getMenu: function() {
                let uri = process.env.VUE_APP_APIHOST+'user/menu';
                
                this.$http.get(uri).then(response => {
                    this.dataMenu   = response.data.list_menu;
                    this.lastMenu   = response.data.last_menu;

                    let app = document.createElement('script')
                    app.setAttribute('src', 'js/appAdmin.js')                    
                    document.head.appendChild(app)
                });
            },
            getUsers: function() {
                let uri = process.env.VUE_APP_APIHOST+'user/info';
                
                this.$http.get(uri).then(res => {
                    this.users = res.data;
                });
            },
            onSubmit: function () {
                this.waiting    = true;
                this.submitted  = true;

                // stop here if form is invalid
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Please complete the form',
                    });

                    this.waiting = false;
                    return;
                } else {    
                    this.$http.post(process.env.VUE_APP_APIHOST + 'user/profile/change_password', {
                        old_password        : this.form.old_password,
                        new_password        : this.form.new_password,
                        retype_new_password : this.form.retype_new_password,
                    })
                    .then(response => {
                        if (response.data.status) {
                            this.$swal({
                                icon: 'success',
                                title: 'Success',
                                text: response.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    this.waiting    = false;
                                    this.submitted  = false;
                                    document.getElementById("btnCloseModal").click();
                                    this.form.old_password        = '';
                                    this.form.new_password        = '';
                                    this.form.retype_new_password = '';
                                }
                            });
                        } else {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: response.data.message,
                            });
                            this.waiting = false;
                        }
                    })
                }
            }
        }
    }
</script>