<template>
        <div class="page-content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="card text-center">
                            <div class="card-body">
                                <img style="width:300px;max-width:100%" src="images/404.svg">
                                <h4 class="mt-4">Page not found</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</template>

<script>
    export default {
        name: 'NotFound',
        metaInfo: {
            title: 'Opps! Page not found',
            titleTemplate: '%s - Tata Hijau Asri'
        },
        data() {
            return {
                label : 'Page not found',
            }
        },
        created() {
        },
        methods: {
        }
    }
</script>